import occInstance from '@/api/instances/occ';
import {CloudinaryApi} from '@/api/cloudinary.js';

const rootUrl = '/rcp/events/';

const RcEventsApi = {
  async saveNewEvent(event) {
    const {data: response} = await occInstance.post(rootUrl, event);
    return response;
  },
  async fetchEvent(id) {
    const {data: response} = await occInstance.get(`${rootUrl}${id}`);
    return response;
  },
  async updateEvent(id, event) {
    const {data: response} = await occInstance.put(`${rootUrl}${id}`, event);
    return response;
  },
  async deleteEvent(event) {
    const {data: response} = await occInstance.delete(`${rootUrl}${event.id}`);
    const bannerAsset = event.assets?.bannerAssetId;
    if (bannerAsset) CloudinaryApi.deleteAssetFromCloudinary(bannerAsset);
    return response;
  },
  async fetchRideClubEventsList(params) {
    const url = `${rootUrl}/list`;
    const apiPayload = {
      ...params,
      limit: 10,
    };
    let response = await occInstance.post(url, apiPayload);
    return response?.data;
  },

  async fetchEventCalendarFile(eventId) {
    const url = `${rootUrl}/${eventId}/download-calendar`;
    const response = await occInstance.get(url);
    return response;
  },
};

export default RcEventsApi;
