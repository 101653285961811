const RideClubScopes = Object.freeze({
  PERSONAL: 'PERSONAL',
  BUSINESS: 'BUSINESS',
  GLOBAL_MARKETING: 'GLOBAL_MARKETING',
  RIDES: 'RIDES',
});

const RideClubB2BUserRoles = Object.freeze({
  ADMIN: 'rideClubAdmin',
  MARKETING_ADMIN: 'TrekRideClubAdmin',
  RIDER: 'B2BRider',
});

const RideClubParticipationOptions = Object.freeze({
  EVENTS: 'EVENTS',
  CHALLENGES: 'CHALLENGES',
});

export {RideClubScopes, RideClubB2BUserRoles, RideClubParticipationOptions};
