import storefrontInstance from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import get from 'lodash/get';
import {userActions, userMutations} from '@/store/modules/user';
import AuthTokens from '@/utils/oauth/auth-tokens';

const UserApi = {
  /**
   * Get data and list of fields and returns either a JSON object or an URL encoded string.
   * @param data Data which contains list of values.
   * @param fields Map of fields (key is source field name and value is target field name).
   * @param urlEncoded Defines whether the return value should be as URL encoded string or a JSON object.
   * @return {string | object}
   * @private
   */
  _initData(data, fields, urlEncoded = true) {
    let result = urlEncoded ? [] : {};
    for (const field in fields) {
      if (urlEncoded) {
        result.push(`${fields[field]}=${encodeURIComponent(get(data, field))}`);
      } else {
        result[fields[field]] = get(data, field);
      }
    }
    return urlEncoded ? result.join('&') : result;
  },

  /**
   * Register user.
   * @param data
   * @return {Promise<boolean>}
   */
  register(data) {
    const defaults = {
      titleCode: 'mr',
      firstName: '',
      lastName: '',
      email: '',
      pwd: '',
      checkPwd: '',
      optin: false,
      optinElectra: false,
      CSRFToken: 'CSRFToken',
    };

    const formData = Object.assign(defaults, data);
    const url = '/register/';
    return storefrontInstance.post(url, formData);
  },

  /**
   * Update user's profile.
   * @param data
   * @return {Promise<void>}
   */
  updateProfile(data) {
    const defaults = {
      titleCode: 'mr',
      firstName: '',
      lastName: '',
      CSRFToken: '',
    };

    const options = Object.assign(defaults, data);
    return storefrontInstance.post('/account/update-profile', options);
  },

  /**
   * Get list of available languages.
   * @return {AxiosPromise<any>}
   */
  retrieveAvailableLanguages() {
    return storefrontInstance.get('/customer/profile/languages/');
  },

  /**
   * Update user's preferred language.
   * @param preferredLanguage
   * @return {AxiosPromise<any>}
   */
  updateLanguage(preferredLanguage) {
    return storefrontInstance.patch(
      '/customer/profile/',
      {
        preferredLanguage,
      },
      {
        params: {
          prop: 'language',
        },
      }
    );
  },

  async getCustomerData() {
    try {
      const response = await storefrontInstance.get('/customer/profile');
      return response;
    } catch (error) {
      throw new Error('Failed to fetch currently logged in customer profile');
    }
  },

  /**
   * Create a user via the guest registration in checkout.
   * @param data
   * @param orderId
   * @return {Promise<void>}
   */
  registerGuestCheckoutUser(orderId, data) {
    return storefrontInstance.post(`/checkout/orderConfirmation/${orderId}`, data);
  },

  /**
   * Update user's preferred retailer from checkout confirmation page.
   * @param retailerName
   * @param orderCode
   * @return {Promise<void>}
   */
  updatePreferredRetailer(retailerName, orderCode) {
    return storefrontInstance.post(
      `/checkout/save-preferred-retailer?storeName=${retailerName}&orderCode=${orderCode}`
    );
  },

  /**
   * Update user's password.
   * @param userId
   * @param data
   * @return {Promise<void>}
   */
  async updatePassword(data = {}) {
    const url = `/users/current/password`;
    const response = await occInstance.put(url, data);
    return response;
  },

  /**
   * Update user's email address.
   * @param data
   * @return {Promise<void>}
   */
  async updateEmailAddress(data = {}) {
    const response = await storefrontInstance.post('/account/update-email/', data);
    AuthTokens.updateToken(response?.data?.data?.userId, response?.data?.data?.token);
    window.store.commit(userMutations.SET_USER_EMAIL, response?.data?.data?.userId);
    return response;
  },

  /**
   * Recovery password.
   * @param apiUrl
   * @param data
   */
  recoveryUpdatePassword(apiUrl, data) {
    const defaults = {
      pwd: '',
      checkPwd: '',
      token: '',
    };

    let options = Object.assign(defaults, data);
    return storefrontInstance.post(apiUrl, options);
  },

  /**
   * Perform a password recovery request.
   * @param data
   * @return {AxiosPromise}
   */
  passwordRecovery(data) {
    const defaults = {
      email: '',
      CSRFToken: '',
    };
    const options = Object.assign(defaults, data);
    return storefrontInstance.post('/login/pw/request/external/', options);
  },

  /**
   * Guest login.
   * @return {Promise<*>}
   */
  async guestLogin(data) {
    const fields = {
      email: 'email',
    };
    const formData = this._initData(data, fields, true);
    const url = '/login/checkout/guest/';
    const response = await storefrontInstance.post(url, formData, {
      withCredentials: true,
      crossorigin: true,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    return get(response, 'request.responseURL', '');
  },
  /**
   * Delete user's account.
   * @return {Promise<void>}
   */
  async deleteAccount() {
    const DELETE_SUCCESS_RESPONSE_CODE = 204;
    const url = 'users/current';
    const response = await occInstance.delete(url);
    if (response.status === DELETE_SUCCESS_RESPONSE_CODE) {
      window.store.dispatch(userActions.LOGOUT_USER);
    }
    return response;
  },

  async checkForDuplicateTrekUUserAccount(email) {
    const response = await storefrontInstance.get('/my-company/manage-users/create/checkUserMigration', {
      params: {emailAddress: email},
    });

    return response;
  },
};

export {UserApi};
