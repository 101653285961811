import storefrontInstance from '@/api/instances/storefront';
import occInstance from '@/api/instances/occ';
import {ApiDefaults} from '@/constants/api.js';
import occ from './instances/occ.config';

const createCartUrl = (url) => `${occ.url.CART_URL}${url}`;

/**
 * Get form data based on input parameters.
 * @param params
 * @return {URLSearchParams}
 */
const getFormData = (params) => {
  const defaults = {
    quantity: 0,
    code: '',
    posName: '',
  };
  const options = Object.assign(defaults, params);
  const formData = new URLSearchParams();

  formData.append('qty', options.quantity);
  formData.append('productCodePost', options.code);
  formData.append('pos', options.posName);

  return formData;
};

const CartApi = {
  /**
   * Add a product to cart.
   * @param params
   * @return {Promise<Object>}
   */
  add(params) {
    const formData = getFormData(params);
    return new Promise((resolve, reject) => {
      storefrontInstance
        .post('/cart/add', formData, {
          headers: {'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'},
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },

  /**
   * Get a user or anonymous cart.
   * @param {String} lang the ISO language code and ISO country code (en_US)
   * @param {String} fields
   * @returns {Promise<Object>}
   */
  async getCart({userId, cartId, lang, curr}, fields = ApiDefaults.FULL) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}`);
    const params = {
      fields,
      lang,
      curr,
    };
    const response = await occInstance.get(url, {params});
    return response?.data;
  },

  /**
   * Dynamic update of cart address for express checkout.
   * @param {Object} address
   * @returns {Promise<Object>}
   */
  async updateCartAddress({userId, cartId}, data = {}) {
    const params = {
      fields: ApiDefaults.DEFAULT,
    };

    const url = createCartUrl(`/users/${userId}/carts/${cartId}/addresses/delivery`);
    const response = await occInstance.post(url, data, {params});
    return response?.data;
  },

  /**
   * Dynamic update of cart dealer and or mode.
   * @param {Object} {dealerCode, deliveryModeId}
   * @returns {Promise}
   */
  async updateCartDealerAndMode({userId, cartId}, {dealerCode = null, deliveryModeId = ''}) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/dealer/${dealerCode}/deliverymode/${deliveryModeId}`);
    const response = await occInstance.put(url, null, null);

    return response;
  },

  /**
   * Assigns a POS retailer to the cart instance.
   * @param {Object} {dealerCode}
   * @returns {Promise}
   */
  async updateCartDealer({userId, cartId}, dealerCode) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/dealers/${dealerCode}`);

    const response = await occInstance.put(url, {dealerCode});
    return response;
  },

  /**
   * Delete's the address on the cart.
   * @returns {Promise}
   */
  async deleteCartAddress({userId, cartId}) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/addresses/delivery`);
    const response = await occInstance.delete(url);
    return response;
  },

  /**
   * Assigns an email to the cart.
   * @param {String} email
   * @returns {Promise}
   */
  async updateCartEmail({userId, cartId}, email = 'guest@user.com') {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/setEmail`);
    const response = await occInstance.put(url, {
      email: email,
    });
    return response.data;
  },

  /**
   * Fetches dealer address using a store number.
   * @param {String} dealerCode
   * @returns {Promise<Object>}
   */
  async getDealerAddress(dealerCode, fields = ApiDefaults.DEFAULT) {
    const url = `/stores/${dealerCode}`;
    const params = {
      fields,
    };

    const response = await occInstance.get(url, {params});
    return response?.data;
  },

  async remove({cartId, userId, entryNumber}) {
    const REMOVE_CART_URL = createCartUrl(`/users/${userId}/carts/${cartId}/entries/${entryNumber}`);
    const response = await occInstance.delete(REMOVE_CART_URL);
    return response.data;
  },

  async updateQuantity({entryNumber, userId, cartId, quantity}) {
    const UPDATE_CART_URL = createCartUrl(`/users/${userId}/carts/${cartId}/entries/${entryNumber}`);
    const response = await occInstance.patch(UPDATE_CART_URL, {quantity});
    return response.data;
  },

  async applyPromotionCode({cartId, userId}, couponCode) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/applyVoucher`);
    return occInstance.post(url, {
      voucherId: couponCode,
    });
  },

  async removePromotionCode({cartId, userId}, couponCode) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/removeVoucher`);
    return occInstance.post(url, {
      voucherId: couponCode,
    });
  },

  async validateCart({cartId, userId}) {
    const VALIDATE_CART_URL = createCartUrl(`/users/${userId}/carts/${cartId}/validate`);
    const cartModificationsResponse = await occInstance.post(VALIDATE_CART_URL);
    return cartModificationsResponse.data;
  },

  /**
   * Get fulfillment options for cart entry
   * @param {String} lang the ISO language code and ISO country code (en_US)
   * @returns {Promise}
   */

  getCartEntryFulfillmentOptions({userId, cartId, lang, curr}, entryNumber) {
    const url = createCartUrl(`/users/${userId}/carts/${cartId}/entries/${entryNumber}/fulfillmentOptions`);
    const params = {
      lang,
      curr,
    };
    return occInstance.get(url, {params});
  },
  /**
   * Update various options for a cart entry (fulfillment selections and more)
   * @param {Object} {dealerCode}
   * @returns {Promise}
   */
  async updateCartEntryOptions(userId, cartId, {deliveryMode, entries}) {
    let payload = {
      orderEntries: entries.map((e) => {
        const entry = {
          entryNumber: e.entryNumber,
          quantity: e.quantity,
          product: {
            code: e.product.code,
          },
        };
        if (deliveryMode) {
          entry.deliveryMode = {
            code: deliveryMode,
          };
        }
        return entry;
      }),
    };

    const response = await occInstance.patch(
      createCartUrl(`/users/${userId}/carts/${cartId}/entries/options`),
      payload
    );
    return response.data;
  },
  async revertToAnonymousCart({cartId, userId}) {
    const response = await occInstance.put(createCartUrl(`/users/${userId}/carts/${cartId}/revertGuest`));
    return response.data;
  },

  async removeOutOfStockCartItems({userId, cartId}, params) {
    const response = await occInstance.delete(createCartUrl(`/users/${userId}/carts/${cartId}/entries?${params}`));
    return response.data;
  },
};

const CustomCartApi = {
  /**
   * Add a product to cart.
   * @param params
   * @return {Promise}
   */
  add(params) {
    return new Promise((resolve, reject) => {
      storefrontInstance
        .post('/cart/addSpectrum', params)
        .then((response) => {
          resolve(response.data);
        })
        .catch((reason) => {
          reject(reason);
        });
    });
  },
};

const updatePrioritizationData = async (payload) => {
  return storefrontInstance.put(`/checkout-b2b/api/order-review/updatePrioritizationData`, payload);
};

export {CartApi, CustomCartApi, updatePrioritizationData};
