import storefrontInstance from '@/api/instances/storefront.js';
import {DeliveryMethodApi} from '@/api/checkout/delivery-method.js';
import CheckoutPaymentHandler from '@/utils/checkout/checkout-payment-handler.js';
import {DeliveryModes, Gr4vySessionStorageKey} from '@/constants/checkout.js';
import {mutationAndActionHelper} from '@/utils/vuex-helpers.js';
import {Gr4vyFields} from '@/utils/checkout/payment-providers/gr4vy-provider';
import {PaymentProvider} from '@/constants/payment-provider';
import {createGr4vySession, validateGr4vySessionId} from '@/api/checkout/gr4vy';
import isEmpty from 'lodash/isEmpty';
import store from '@/store';

const MutationTypes = Object.freeze({
  SET_ERROR_MESSAGES: 'setErrorMessages',
  SET_CHECKOUT_DIALOG_VISIBILITY: 'setIsCheckoutDialogVisible',
  SET_CONTINUE_SHOPPING_URL: 'setContinueShoppingUrl',
  SET_VALIDATION_URL: 'setValidationUrl',
  SET_IS_APPLE_BUTTON_ENABLED: 'setIsAppleButtonEnabled',
  SET_BILLING_ADDRESS: 'setBillingAddress',
});

const ActionTypes = Object.freeze({
  GET_DELIVERY_MODES: 'getDeliveryModes',
  GET_BILLING_ADDRESS_OBJECT: 'getBillingAddressObject',
});

export const checkoutMutations = mutationAndActionHelper(MutationTypes, 'checkout');
export const checkoutActions = mutationAndActionHelper(ActionTypes, 'checkout');

const Roles = Object.freeze({
  TREK_U: 'b2btrekuemployeepurchasegroup',
});

const checkoutModule = {
  namespaced: true,

  state: {
    b2cCartData: null,
    b2cCartDataFull: null,
    b2bCartData: null,
    errorMessages: null,
    isCheckoutDialogVisible: false,
    isAppleButtonEnabled: true,
    loading: true,
    savedState: false,
    paymentData: {
      cardNumber: undefined,
      cardName: undefined,
      cvn: undefined,
      cardType: undefined,
      expiryMonth: undefined,
      expiryYear: undefined,
      shouldSaveBillingInfo: false,
      bankName: undefined,
      kcpPaymentMethod: undefined,
    },
    billingAddress: {
      firstName: undefined,
      lastName: undefined,
      line1: undefined,
      line2: undefined,
      phone: undefined,
      postcode: undefined,
      regionIso: undefined,
      townCity: undefined,
    },
    continueShoppingUrl: 'https://trekbikes.com',
    selectedPaymentProvider: null,
    selectedDeliveryMode: null,
    selectedStore: null,
    deliveryMethodsLoading: true,
    isDealersLoading: true,
    deliveryModesLoading: true,
    deliveryModes: [],
    stores: [],
    dealerPostcode: '',
    hasBikes: false,
    isVisibleDeliveryModes: true,
    shipToStoreMode: false,
    isSuppressingHomeDelivery: false,
    validationUrl: '',
    gr4vyProvider: undefined,
    gr4vyEvents: undefined,
    gr4vyFormValidations: {},
    cardSchema: undefined,
    outOfDealerDeliveryRangeToast: false,
    hasReachedBikePurchaseLimit: false,
  },

  getters: {
    trackCartEntries(state) {
      if (state.b2cCartData === null) return;

      let mappedEntries = state.b2cCartData.map((entry) => {
        let variantMatrix = entry.product.variantMatrix; // Hybris array matrix splits color and size
        let variantColor = variantMatrix[0].variantValueCategory.name;
        let variantSize = variantMatrix.length > 1 ? variantMatrix[1].variantValueCategory.name : 'No Size Available';

        return {
          id: entry.product.code,
          name: entry.product.displayName,
          price: entry.basePrice.value,
          brand: entry.product.brandNameFull,
          category: entry.product.defaultCategory,
          variant: variantColor,
          size: variantSize,
          quantity: entry.quantity,
        };
      });

      return mappedEntries;
    },
    hasExpressCheckoutData(state) {
      if (state.hasBikes) {
        return !!state.selectedStore && !!state.selectedDeliveryMode;
      }
      if (state.selectedDeliveryMode === DeliveryModes.CLICK_AND_COLLECT_NO_BIKE) {
        return !!state.selectedStore;
      }
      return !!state.selectedDeliveryMode;
    },
    isPageLoading(state) {
      return state.loading;
    },
    bikes(state) {
      return state.b2bCartData?.bikes;
    },
    parts(state) {
      return state.b2bCartData?.parts;
    },
    customWaterBottles(state) {
      return state.b2bCartData?.customWaterBottles;
    },
    projectOneBikes(state) {
      return state.b2bCartData?.projectOneBikes;
    },
    cartSummary(state) {
      return state.b2bCartData?.cartSummary;
    },

    isRestrictedToRetailer() {
      return store?.getters['user/b2bUserRoles']?.roles?.includes(Roles.TREK_U);
    },
  },

  mutations: {
    setB2cCartData(state, payload) {
      // TODO: get cartData from BE so data is consistent through checkout process, remove check
      const isOrderData = payload.some((item) => item?.product);
      state.b2cCartData = isOrderData ? payload : payload[0].entries;
    },
    setB2cCartDataFull(state, payload) {
      state.b2cCartDataFull = payload;
    },
    setB2bCartData(state, data) {
      const keys = ['bikes', 'customWaterBottles', 'parts', 'projectOneBikes'];

      keys.forEach((key) => {
        data[key]?.warehouses?.forEach((warehouse) => {
          warehouse.entries?.forEach((entry) => {
            entry.product = {
              code: entry.sku,
              itemName: entry.itemName,
            };
          });
        });
      });

      state.b2bCartData = data;
    },
    [MutationTypes.SET_CHECKOUT_DIALOG_VISIBILITY](state, payload) {
      state.isCheckoutDialogVisible = payload;
    },
    [MutationTypes.SET_IS_APPLE_BUTTON_ENABLED](state, payload) {
      state.isAppleButtonEnabled = payload;
    },
    [MutationTypes.SET_ERROR_MESSAGES](state, payload) {
      state.errorMessages = payload;
    },
    [MutationTypes.SET_CONTINUE_SHOPPING_URL](state, payload) {
      state.continueShoppingUrl = payload;
    },
    setAttributesByKey(state, {partTypeKey, sku, value, key}) {
      let modifiedCartData = state.b2bCartData;
      modifiedCartData[partTypeKey].warehouses.forEach((warehouse) => {
        const entryIndex = warehouse.entries.findIndex((entry) => entry.sku === sku);
        if (entryIndex > -1) {
          warehouse.entries[entryIndex][key] = value;
        }
      });

      state.b2bCartData = modifiedCartData;
    },
    setLoading(state, payload) {
      state.loading = payload;
    },
    setSavedState(state, payload) {
      state.savedState = payload;
    },
    setSelectedPaymentProvider(state, payload) {
      state.selectedPaymentProvider = payload;
    },
    setDealers(state, payload) {
      state.stores = payload;
    },
    setDeliveryMethodsLoading(state, payload) {
      state.deliveryMethodsLoading = payload;
    },
    setIsDealersLoading(state, payload) {
      state.isDealersLoading = payload;
    },
    setDeliveryModes(state, payload) {
      state.deliveryModes = payload;
    },
    setDeliveryModesLoading(state, payload) {
      state.deliveryModesLoading = payload;
    },
    setSelectedDeliveryMode(state, option) {
      state.selectedDeliveryMode = option;
    },
    setSelectedStore(state, option) {
      state.selectedStore = option;
    },
    setDealerPostcode(state, payload) {
      state.dealerPostcode = payload;
    },
    setHasBikes(state, payload) {
      state.hasBikes = payload;
    },
    setIsVisibleDeliveryModes(state, payload) {
      state.isVisibleDeliveryModes = payload;
    },
    setShipToStoreMode(state, payload) {
      state.shipToStoreMode = payload;
    },
    setCardNumber(state, cardNumber) {
      state.paymentData.cardNumber = cardNumber;
    },
    setCardName(state, cardName) {
      state.paymentData.cardName = cardName;
    },
    setCvn(state, cvn) {
      state.paymentData.cvn = cvn;
    },
    setCardType(state, cardType) {
      state.paymentData.cardType = cardType;
    },
    setExpiryMonth(state, expiryMonth) {
      state.paymentData.expiryMonth = expiryMonth;
    },
    setExpiryYear(state, expiryYear) {
      state.paymentData.expiryYear = expiryYear;
    },
    setShouldSaveBillingInfo(state, shouldSaveBillingInfo) {
      state.paymentData.shouldSaveBillingInfo = shouldSaveBillingInfo;
    },
    setBankName(state, bankName) {
      state.paymentData.bankName = bankName;
    },
    setKcpPaymentMethod(state, kcpPaymentMethod) {
      state.paymentData.kcpPaymentMethod = kcpPaymentMethod;
    },
    [MutationTypes.SET_BILLING_ADDRESS](state, payload) {
      Object.keys(payload).forEach((key) => {
        if (key in state.billingAddress && payload[key]) {
          state.billingAddress[key] = payload[key];
        }
      });
    },
    setFirstName(state, firstName) {
      state.billingAddress.firstName = firstName;
    },
    setLastName(state, lastName) {
      state.billingAddress.lastName = lastName;
    },
    setLine1(state, line1) {
      state.billingAddress.line1 = line1;
    },
    setLine2(state, line2) {
      state.billingAddress.line2 = line2;
    },
    setPhone(state, phone) {
      state.billingAddress.phone = phone;
    },
    setPostcode(state, postcode) {
      state.billingAddress.postcode = postcode;
    },
    setRegionIso(state, regionIso) {
      state.billingAddress.regionIso = regionIso;
    },
    setTownCity(state, townCity) {
      state.billingAddress.townCity = townCity;
    },
    setIsSuppressingHomeDelivery(state, payload) {
      state.isSuppressingHomeDelivery = payload;
    },
    setValidationUrl(state, payload) {
      state.validationUrl = payload;
    },
    setIsGr4vyFieldValid(state, {id, valid, dirty}) {
      state.gr4vyFormValidations = {
        ...state.gr4vyFormValidations,
        [id]: {
          valid,
          dirty,
        },
      };
    },
    setCardSchema(state, schema) {
      state.cardSchema = schema;
    },
    setOutOfDealerDeliveryRangeToast(state, boolean) {
      state.outOfDealerDeliveryRangeToast = boolean;
    },
    setBikePurchaseLimitReached(state, payload) {
      state.hasReachedBikePurchaseLimit = payload;
    },
  },

  actions: {
    async getDealers({state, commit, rootGetters}) {
      commit('setIsDealersLoading', true);
      try {
        const response = await DeliveryMethodApi.getDealers(
          rootGetters['backend/occUrlParams'],
          state.dealerPostcode,
          state.selectedDeliveryMode
        );
        commit('setDealers', response);
        commit('setIsDealersLoading', false);
      } catch (error) {
        console.error('Error fetching dealers:', error);
        commit('setIsDealersLoading', false);
      } finally {
        let selectedStore = state.stores?.find((store) => store.isSelected)?.name;
        commit('setSelectedStore', selectedStore);
      }
    },
    async [ActionTypes.GET_DELIVERY_MODES]({state, commit, rootGetters}) {
      commit('setDeliveryModesLoading', true);
      try {
        const response = await DeliveryMethodApi.getDeliveryModes(
          rootGetters['backend/occUrlParams'],
          state.selectedStore,
          state.isSuppressingHomeDelivery
        );
        commit('setDeliveryModes', response);
        commit('setDeliveryModesLoading', false);
      } catch (error) {
        console.error('Error fetching delivery modes:', error);
        commit('setDeliveryModesLoading', false);
      } finally {
        let selectedDeliveryMode = state.deliveryModes?.find((mode) => mode.isSelected)?.code;
        commit('setSelectedDeliveryMode', selectedDeliveryMode);
      }
    },
    async submitDeliveryMethod({state}) {
      try {
        let deliveryMethodData = {
          selectedDeliveryMethod: state.selectedDeliveryMode ?? '',
          preferredDealerCode: state.selectedStore ?? '',
        };
        const response = await DeliveryMethodApi.submitDeliveryMethod(deliveryMethodData);
        return response;
      } catch (error) {
        console.error('Error sending delivery method:', error);
      }
    },
    async initializePayment({state, dispatch}) {
      if (!state.selectedPaymentProvider.Provider) return CheckoutPaymentHandler.init(state.selectedPaymentProvider);

      const gr4vyData = {
        secureFields: state.gr4vyInstance?.secureFields,
        cardVaultSuccessEvent: state.gr4vyEvents?.CARD_VAULT_SUCCESS,
        cardVaultFailureEvent: state.gr4vyEvents?.CARD_VAULT_FAILURE,
      };

      const providerPayload = {
        ...state.paymentData,
        validationCallFailure: (error) => dispatch('handlePaymentError', error.payload),
        billingAddress: state.billingAddress,
        ...(state.selectedPaymentProvider.value === PaymentProvider.GR4VY ? gr4vyData : {}),
        providerCode: state.selectedPaymentProvider.value,
      };

      const provider = new state.selectedPaymentProvider.Provider(providerPayload);

      try {
        await provider.initializePayment();
      } catch (error) {
        console.error('Failed to initialize payment:', error);
        if (error.payload) {
          dispatch('handlePaymentError', error.payload);
        }
      }
    },
    async handlePaymentError({commit, rootState}, payload) {
      commit(MutationTypes.SET_ERROR_MESSAGES, payload);
      const {errorFields, linkTarget} = payload;
      if (!errorFields && !linkTarget) throw new Error('Unknown payment init failure');
      if (linkTarget) window.location.href = `${rootState.backend.encodedContextPath}${linkTarget}`;
      if (errorFields) {
        const errorEvent = new CustomEvent('billingDetailsError', {detail: errorFields});
        document.dispatchEvent(errorEvent);
      }
    },
    async loadB2bCart({commit}, endpoint) {
      await storefrontInstance
        .get(endpoint)
        .then(({data}) => {
          commit('setB2bCartData', data.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async createGr4vyFields({state, rootState}, {SecureFieldsModule}) {
      const getGr4vyInstance = (sessionId) => {
        const gr4vyInstance = new Gr4vyFields({
          SecureFieldsModule,
          sessionId,
          environment: rootState?.backend?.isProduction ? 'production' : 'sandbox',
        });

        state.gr4vyEvents = SecureFieldsModule.Events;
        state.gr4vyInstance = gr4vyInstance;

        return gr4vyInstance;
      };

      const createOrUpdateSession = async () => {
        const {id} = await createGr4vySession();
        localStorage.setItem(Gr4vySessionStorageKey, id);
        return id;
      };

      try {
        const gr4vySessionId = localStorage.getItem(Gr4vySessionStorageKey);
        if (gr4vySessionId) {
          const {id} = await validateGr4vySessionId(gr4vySessionId);
          return getGr4vyInstance(id);
        }
      } catch (error) {
        console.warn('Session validation failed, creating a new session.');
      }

      const newSessionId = await createOrUpdateSession();
      return getGr4vyInstance(newSessionId);
    },
    getIsGr4vyPaymentFormValid({state}) {
      if (state.selectedPaymentProvider?.value !== PaymentProvider.GR4VY || isEmpty(state.gr4vyFormValidations))
        return true;
      const isValid = Object.values(state.gr4vyFormValidations).every(({valid}) => Boolean(valid));
      return isValid;
    },
    [ActionTypes.GET_BILLING_ADDRESS_OBJECT]({commit}, payload) {
      const {region, ...refinedPayload} = payload;
      const updatedPayload = {...refinedPayload, regionIso: region};
      commit(MutationTypes.SET_BILLING_ADDRESS, updatedPayload);
    },
  },
};

export default checkoutModule;
