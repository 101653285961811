<template>
  <div
    :id="id"
    class="editorial-list"
    :class="divClass"
    :style="{marginTop: removeTopGap ? '-60px' : ''}"
    :[wrapperTagAttr]="true"
  >
    <component :is="headingTag" v-if="title || headingLink" class="list-heading">
      <template v-if="headingLink && title">
        <a :href="headingUrl" class="headingLinked">
          <span :class="headingClass" v-html="title" />
        </a>
        <span :class="`headingUnlinked ${headingClass}`" v-html="title" />
      </template>
      <template v-else-if="headingLink && isTitleEmpty">
        <slot name="heading-slot"></slot>
      </template>
      <template v-else>
        <span :class="headingClass" v-html="title" />
      </template>
    </component>
    <component :is="listTag">
      <slot name="list-items"></slot>
    </component>
  </div>
</template>
<script>
export default {
  name: 'TrekListComponent',
  props: {
    id: {
      type: String,
      required: true,
    },
    divClass: {
      type: String,
      default: '',
    },
    headingTag: {
      type: String,
      required: true,
    },
    removeTopGap: {
      type: Boolean,
      required: true,
    },
    listTag: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    wrapperTagAttr: {
      type: String,
      required: true,
    },
    headingLink: {
      type: Boolean,
    },
    headingUrl: {
      type: String,
      required: true,
    },
    headingClass: {
      type: String,
      required: true,
    },
  },
  computed: {
    isTitleEmpty() {
      return this.title === '';
    },
  },
};
</script>
