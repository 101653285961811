import occInstance from '@/api/instances/occ';

export const createGr4vySession = async () => {
  const url = `payment/gr4vy/session`;
  const sessionResponse = await occInstance.get(url);
  return sessionResponse.data;
};

export const validateGr4vySessionId = async (id) => {
  const url = `payment/gr4vy/session/${id}`;
  const sessionResponse = await occInstance.get(url);
  return sessionResponse.data;
};
