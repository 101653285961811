import {computed} from 'vue';
import store from '@/store';

export function useCartUtils() {
  const cartUrl = computed(() => {
    const {hostUrl, trekBrandNavURL} = store.state.backend;
    const {selectedLocation, selectedRetailer} = store.state.user;

    return hostUrl.includes('electra')
      ? `${trekBrandNavURL}/cart?clear=true&retailer=${selectedRetailer?.name}&postCode=${selectedLocation?.postcode}`
      : `${trekBrandNavURL}/cart`;
  });

  return {cartUrl};
}
