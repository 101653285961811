import RcEventsApi from '@/rideClubEvents/utils/rc-events-api.js';
import {ChallengeAudience} from '@/constants/rcp-challenges.js';
import {RideClubScopes} from '@/constants/ride-club.js';

const rcEvents = {
  namespaced: true,
  state: {
    eventsList: [],
    activeEvent: {},
    isEventLoading: false,
    isEventDeleteDialogOpen: false,
    hasUnsavedChanges: false,
    eventIdToClone: undefined,
  },
  mutations: {
    setEventsList(state, payload) {
      state.eventsList = payload;
    },
    setIsEventLoading(state, payload) {
      state.isEventLoading = payload;
    },
    setHasUnsavedChanges(state, payload) {
      state.hasUnsavedChanges = payload;
    },
    setActiveEvent(state, payload) {
      state.activeEvent = payload;
    },
    setDeleteDialogStatus(state, payload) {
      state.isEventDeleteDialogOpen = payload;
    },
    setEventIdToClone(state, payload = undefined) {
      state.eventIdToClone = payload;
    },
  },
  actions: {
    async fetchEvent({commit}, eventPayload) {
      try {
        commit('setIsEventLoading', true);
        let response = await RcEventsApi.fetchEvent(eventPayload.eventId);
        let searchScope = {
          ...response.searchScope,
          locationPoint: {
            lat: response.location?.geolocation?.geometry?.coordinates[1] || 0,
            lng: response.location?.geolocation?.geometry?.coordinates[0] || 0,
          },
        };
        response = {...response, searchScope};
        if (response?.searchScope?.type === ChallengeAudience.COUNTRY && !eventPayload.isClone) {
          response.countryCodes = response?.searchScope?.countryCodes;
        }
        if (response?.scope === RideClubScopes.GLOBAL_MARKETING && !response?.searchScope?.type) {
          response.searchScope.type = ChallengeAudience.COUNTRY;
        }
        if (!response.location.geolocation) {
          let geolocation = {
            type: 'Feature',
            geometry: {
              type: 'Point',
              coordinates: [],
            },
          };
          response.location.geolocation = geolocation;
        }
        if (eventPayload.isClone) {
          response.title = `${response.title} (Copy)`;
          response.id = '';
          response.status = '';
          response.isClone = true;
        }
        commit('setActiveEvent', response);
        commit('setIsEventLoading', false);
      } catch (error) {
        commit('setIsEventLoading', false);
        throw error.cause.data;
      }
    },
    clearActiveEvent({commit}) {
      commit('setActiveEvent', {});
    },
  },
};

export default rcEvents;
