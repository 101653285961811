import Vue from 'vue';
import {asyncQueryAction} from '@/store/util/store-utils';
import {UserApi} from '@/api/user';
import CryptoUtils from '@/utils/oauth/crypto-utils';
import {RideClubB2BUserRoles} from '@/constants/ride-club.js';
import RetailerApi from '@/api/retailer-api';
import UserOccApi from '@/api/user-occ.js';
import occInstance from '@/api/instances/occ';
import storefrontInstance from '@/api/instances/storefront';
import {routeMapScope} from '@/rcpBuilder/utils/save-route-helpers';
import {
  getValidLocationFromPostCode,
  getValidLocationFromCoordinates,
  getAddressFromCoordinates,
} from '@/components/mapbox/utils/mapbox-api.js';
import {UserStatus} from '@/constants/backend';
import {Url} from '@/utils/url';

const mutationTypes = Object.freeze({
  SET_B2B_UNIT: 'setB2bUnit',
  FETCH_USER_REQUEST: 'FETCH_USER_REQUEST',
  FETCH_USER_FAILURE: 'FETCH_USER_FAILURE',
  FETCH_USER_SUCCESS: 'FETCH_USER_SUCCESS',
  SET_ACCESS_TOKEN: 'SET_ACCESS_TOKEN',
  SET_REFRESH_TOKEN: 'SET_REFRESH_TOKEN',
  SET_SESSIONID: 'SET_SESSIONID',
  SET_VALIDATED_LOCATION: 'SET_VALIDATED_LOCATION',
  SET_IV: 'SET_IV',
  FETCH_RETAILER_REQUEST: 'FETCH_RETAILER_REQUEST',
  FETCH_RETAILER_SUCCESS: 'FETCH_RETAILER_SUCCESS',
  FETCH_RETAILER_FAILURE: 'FETCH_RETAILER_FAILURE',
  SET_SELECTED_RETAILER: 'setSelectedRetailer',
  SET_USER_PREFERRED_RETAILER: 'setUserPreferredRetailer',
  SET_HAS_REFERRED_SHOP_RETAILER_LOADED: 'setHasReferredShopRetailerLoaded',
  SET_HAS_PREFERRED_RETAILER_LOADED: 'setHasPreferredRetailerLoaded',
  SET_TOKENS: 'SET_TOKENS',
  CLEAR_USER_PERMISSIONS: 'CLEAR_USER_PERMISSIONS',
  CLEAR_USER: 'CLEAR_USER',
  CLEAR_SELECTED_RETAILER: 'CLEAR_SELECTED_RETAILER',
  CLEAR_SELECTED_LOCATION: 'CLEAR_SELECTED_LOCATION',
  SET_USER_ROLES: 'setUserRoles',
  SET_USER_PROFILE: 'setUserProfile',
  SET_USER_GENDER_LIST: 'setUserGenderList',
  SET_ANONYMOUS_RETAILER_FLAG: 'SET_ANONYMOUS_RETAILER_FLAG',
  SET_USER_EMAIL: 'SET_USER_EMAIL',
  SET_SELECTED_LOCATION: 'setSelectedLocation',
  UPDATE_TREKU_COMPETENCY: 'updateTrekUCompetency',
  SET_DRAWER_DELIVERY_ADDRESS: 'setDrawerDeliveryAddress',
});

const actionTypes = Object.freeze({
  LOAD_CURRENT_USER: 'loadCurrentUser',
  SAVE_TOKENS: 'saveTokens',
  FETCH_USER_ROLES: 'fetchUserRoles',
  FETCH_USER_PROFILE: 'fetchUserProfile',
  FETCH_USER_PREFERRED_RETAILER: 'fetchUserPreferredRetailer',
  FETCH_GENDER_LIST: 'fetchGenderList',
  LOAD_REFERRED_SHOP_RETAILER: 'loadReferredShopRetailer',
  LOAD_SELECTED_RETAILER: 'loadSelectedRetailer',
  LOAD_NEAREST_RETAILER: 'loadNearestRetailer',
  LOAD_ANONYMOUS_RETAILER: 'loadAnonymousRetailer',
  LOAD_SESSION_RETAILER: 'loadSessionRetailer',
  LOGOUT_USER: 'logoutUser',
  INIT_SELECTED_LOCATION: 'initSelectedLocation',
  CLEAR_USER_PREFERENCES: 'clearUserPreferences',
  SET_SELECTED_LOCATION_FROM_POSTCODE: 'setSelectedLocationFromPostCode',
  SET_SELECTED_LOCATION_FROM_COORDINATES: 'setSelectedLocationFromCoordinates',
});

const competencyTypes = Object.freeze({
  USER_TYPE: 'Electra',
  COMMON_SUFFIX: 'EP',
});

export const userMutations = Object.entries(mutationTypes).reduce((mutationObj, [key, value]) => {
  mutationObj[key] = `user/${value}`;
  return mutationObj;
}, {});

export const userActions = Object.entries(actionTypes).reduce((actionObj, [key, value]) => {
  actionObj[key] = `user/${value}`;
  return actionObj;
}, {});

const mutations = {
  [mutationTypes.SET_B2B_UNIT](state, value) {
    Vue.set(state, 'b2bUnit', value);
  },
  [mutationTypes.FETCH_USER_REQUEST](state) {
    state.isUserDataLoading = true;
  },
  [mutationTypes.FETCH_USER_SUCCESS](state, value) {
    state.isUserDataLoading = false;
    state.customer = value;
  },
  [mutationTypes.FETCH_USER_FAILURE](state) {
    state.isUserDataLoading = false;
    state.customer = undefined;
  },
  [mutationTypes.CLEAR_USER](state) {
    state.customer = undefined;
  },
  [mutationTypes.SET_ACCESS_TOKEN](state, accessToken) {
    state.accessToken = accessToken;
  },
  [mutationTypes.SET_REFRESH_TOKEN](state, refreshToken) {
    state.refreshToken = refreshToken;
  },
  [mutationTypes.SET_VALIDATED_LOCATION](state, payload) {
    state.hasValidatedLocation = payload;
  },
  [mutationTypes.SET_IV](state, iv) {
    state.iv = iv;
  },
  [mutationTypes.SET_SESSIONID](state, sessionId) {
    state.sessionId = sessionId;
  },
  [mutationTypes.CLEAR_USER_PERMISSIONS](state) {
    state.accessToken = undefined;
    state.refreshToken = undefined;
    state.iv = undefined;
    state.sessionId = undefined;
    state.userRoles = {};
  },
  [mutationTypes.SET_USER_ROLES](state, userRoles) {
    state.userRoles = userRoles;
  },
  [mutationTypes.SET_USER_PROFILE](state, userProfile) {
    state.userProfile = userProfile;
  },
  [mutationTypes.SET_USER_GENDER_LIST](state, userGenderList) {
    state.userGenderList = userGenderList;
  },
  [mutationTypes.SET_USER_PREFERRED_RETAILER](state, userPreferredRetailer) {
    state.userPreferredRetailer = userPreferredRetailer;
  },
  [mutationTypes.FETCH_RETAILER_REQUEST](state) {
    state.isRetailerLoading = true;
  },
  [mutationTypes.FETCH_RETAILER_SUCCESS](state, retailer) {
    state.isRetailerLoading = false;
    state.hasFetchRetailerError = false;

    if (!retailer) return;
    state.selectedRetailer = retailer;
  },
  [mutationTypes.SET_HAS_REFERRED_SHOP_RETAILER_LOADED](state, payload) {
    state.hasReferredShopRetailerLoaded = payload;
  },
  [mutationTypes.SET_SELECTED_RETAILER](state, retailer) {
    state.selectedRetailer = retailer;
  },
  [mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED](state, payload) {
    state.hasPreferredRetailerLoaded = payload;
  },
  [mutationTypes.FETCH_RETAILER_FAILURE](state, error) {
    state.isRetailerLoading = false;
    if (error) {
      console.error(error);
      state.hasFetchRetailerError = true;
    }
  },
  [mutationTypes.CLEAR_SELECTED_RETAILER](state) {
    state.selectedRetailer = {};
  },
  [mutationTypes.CLEAR_SELECTED_LOCATION](state) {
    state.selectedLocation = {};
  },
  [mutationTypes.SET_ANONYMOUS_RETAILER_FLAG](state, status) {
    state.hasAnonymousRetailerLoaded = status;
  },
  [mutationTypes.SET_USER_EMAIL](state, email) {
    state.userEmail = email;
  },
  [mutationTypes.SET_SELECTED_LOCATION](state, location) {
    state.selectedLocation = location;
  },
  [mutationTypes.UPDATE_TREKU_COMPETENCY](state, trekUCompetencyLevel) {
    state.trekUCompetencyLevel = trekUCompetencyLevel;
  },
  [mutationTypes.SET_DRAWER_DELIVERY_ADDRESS](state, address) {
    state.drawerDeliveryAddress = address;
  },
};

const getters = {
  b2bUnitShippingDays: (state) => {
    if (!state.b2bUnit?.shippingDays?.length) {
      return null;
    } else {
      let translatedShippingDays = [];
      state.b2bUnit.shippingDays.forEach((day) => {
        translatedShippingDays.push(window.vm.$t(`storeDetails.openingSchedule.weekday.${day}`));
      });
      return translatedShippingDays.join(', ');
    }
  },
  b2bUserRoles: (state, _moduleGetters, rootState) => {
    let b2bCustomerRole;
    if (!rootState.backend.isB2Bsite) return null;
    b2bCustomerRole = state.userRoles?.b2bCustomerRoles?.find(
      (b2bRoleObj) => b2bRoleObj.uid === rootState.backend.currentB2BUnit
    );
    return b2bCustomerRole;
  },
  b2bRideClubUserRole: (state, moduleGetters) => {
    const roles = moduleGetters.b2bUserRoles?.roles;
    const b2bRideClubUserRole = roles?.find((element) => {
      return (
        element === RideClubB2BUserRoles.ADMIN ||
        element === RideClubB2BUserRoles.MARKETING_ADMIN ||
        element === RideClubB2BUserRoles.RIDER
      );
    });
    return b2bRideClubUserRole;
  },
  b2bUserRoleBasedUnits: (state, moduleGetters, rootState) => {
    let b2bUserRoleBasedUnits;
    if (!rootState.backend.isB2Bsite || moduleGetters.b2bRideClubUserRole === RideClubB2BUserRoles.RIDER) return [];
    b2bUserRoleBasedUnits = state.userRoles?.b2bCustomerRoles?.filter((b2bRoleObj) => {
      return b2bRoleObj.roles?.includes(moduleGetters.b2bRideClubUserRole);
    });
    return b2bUserRoleBasedUnits;
  },

  b2bUserAdminUnits: (state) => {
    let b2bUserAdminUnits;
    b2bUserAdminUnits = state.userRoles?.b2bCustomerRoles?.filter((b2bRoleObj) => {
      return b2bRoleObj.roles?.includes(RideClubB2BUserRoles.ADMIN);
    });
    return b2bUserAdminUnits;
  },

  rideClubUserScope: (state, moduleGetters, rootState) => {
    return routeMapScope(rootState.backend.isB2Bsite, moduleGetters.b2bRideClubUserRole);
  },

  trekUCompetencyLevelTruncated: (state) => {
    if (state.trekUCompetencyLevel?.includes(competencyTypes.USER_TYPE)) {
      return state.trekUCompetencyLevel.split(' ').slice(0, 2).join(' ');
    }
    return state.trekUCompetencyLevel?.split(' ')[0].concat(' ', competencyTypes.COMMON_SUFFIX);
  },
};

const actions = {
  async [actionTypes.FETCH_USER_ROLES]({commit}) {
    try {
      const response = await occInstance.get(`/users/current?fields=DEFAULT`);
      const userRoles = response?.data;
      commit('setUserRoles', userRoles);
    } catch (error) {
      console.error('Error in fetching UserRoles:', error);
    }
  },
  async [actionTypes.FETCH_USER_PROFILE]({commit}, payload) {
    try {
      const userProfile = payload ? payload : await UserOccApi.fetchUserProfile();
      commit('setUserProfile', userProfile);
    } catch (error) {
      console.error('Error in fetching UserProfile:', error);
    }
  },
  async [actionTypes.FETCH_GENDER_LIST]({commit, rootState}) {
    const payloadObj = {lang: rootState.backend?.language};
    try {
      const userGenderList = await UserOccApi.fetchGenderList(payloadObj);
      commit('setUserGenderList', userGenderList);
    } catch (error) {
      console.error('Error in fetching UserGenderList:', error);
    }
  },
  async [actionTypes.FETCH_USER_PREFERRED_RETAILER]({commit}) {
    try {
      const {data: userPreferredRetailerResponse} = await storefrontInstance.get(
        '/store-finder/get-preferred-retailer/'
      );
      const userPreferredRetailer = userPreferredRetailerResponse.data;
      commit(mutationTypes.SET_USER_PREFERRED_RETAILER, userPreferredRetailer);
    } catch (error) {
      console.error('Error in fetching User Preferred Retailer:', error);
    }
  },
  async [actionTypes.LOAD_CURRENT_USER]({commit}) {
    const loadUserData = async () => {
      const userData = (await UserApi.getCustomerData())?.data?.data;
      return userData;
    };

    commit(mutationTypes.SET_ANONYMOUS_RETAILER_FLAG, false);

    await asyncQueryAction(
      loadUserData,
      mutationTypes.FETCH_USER_REQUEST,
      mutationTypes.FETCH_USER_SUCCESS,
      mutationTypes.FETCH_USER_FAILURE
    ).run({commit});
  },
  async [actionTypes.LOAD_REFERRED_SHOP_RETAILER]({commit, state, dispatch, rootState}) {
    if (rootState.backend?.b2b) return;

    const loadReferredShopRetailer = async () => {
      try {
        const shopId = Url.parse(window.location.href).shop;

        const referredShopRetailerResponse = await RetailerApi.getById(shopId);
        dispatch(actionTypes.SET_SELECTED_LOCATION_FROM_COORDINATES, {
          latitude: referredShopRetailerResponse?.geoPoint?.latitude,
          longitude: referredShopRetailerResponse?.geoPoint?.longitude,
        });
        commit(mutationTypes.SET_HAS_REFERRED_SHOP_RETAILER_LOADED, true);
        commit(mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED, false);
        commit(mutationTypes.SET_ANONYMOUS_RETAILER_FLAG, false);
        return referredShopRetailerResponse;
      } catch (error) {
        if (error.response?.status !== 404) throw new Error(error);
        if (Object.keys(state.selectedRetailer).length) return;
        dispatch(actionTypes.LOAD_NEAREST_RETAILER);
      }
    };
    await asyncQueryAction(
      loadReferredShopRetailer,
      mutationTypes.FETCH_RETAILER_REQUEST,
      mutationTypes.FETCH_RETAILER_SUCCESS,
      mutationTypes.FETCH_RETAILER_FAILURE
    ).run({commit});
  },
  async [actionTypes.LOAD_SELECTED_RETAILER]({commit, state, dispatch, rootState}) {
    if (rootState.backend?.b2b) return;

    const loadSelectedRetailer = async () => {
      if (state.hasPreferredRetailerLoaded) return;
      try {
        const {data: preferredRetailerResponse} = await storefrontInstance.get('/store-finder/get-preferred-retailer/');
        const preferredRetailer = preferredRetailerResponse.data;
        dispatch(actionTypes.SET_SELECTED_LOCATION_FROM_COORDINATES, {
          latitude: preferredRetailer?.geoPoint?.latitude,
          longitude: preferredRetailer?.geoPoint?.longitude,
        });
        commit(mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED, true);
        commit(mutationTypes.SET_HAS_REFERRED_SHOP_RETAILER_LOADED, false);
        return preferredRetailer;
      } catch (error) {
        if (error.response?.status !== 404) throw new Error(error);
        if (Object.keys(state.selectedRetailer).length) return;
        dispatch(actionTypes.LOAD_NEAREST_RETAILER);
      }
    };
    await asyncQueryAction(
      loadSelectedRetailer,
      mutationTypes.FETCH_RETAILER_REQUEST,
      mutationTypes.FETCH_RETAILER_SUCCESS,
      mutationTypes.FETCH_RETAILER_FAILURE
    ).run({commit});
  },
  async [actionTypes.LOAD_NEAREST_RETAILER]({commit, state, rootState, dispatch}) {
    let latitude, longitude;

    if (rootState.backend.geolocation?.accuracyRadiusWithinLimit === true) {
      latitude = rootState.backend.geolocation?.latitude;
      longitude = rootState.backend.geolocation?.longitude;
    } else {
      latitude = state.selectedLocation?.latitude;
      longitude = state.selectedLocation?.longitude;
    }

    if (!latitude && !longitude) {
      commit(mutationTypes.SET_SELECTED_RETAILER, {});
      commit(mutationTypes.SET_SELECTED_LOCATION, {});
    } else {
      const loadNearestRetailer = async () => {
        const payload = {
          latitude,
          longitude,
        };
        const nearestRetailer = await RetailerApi.getNearestRetailer(payload);
        dispatch(actionTypes.SET_SELECTED_LOCATION_FROM_COORDINATES, {
          latitude: nearestRetailer?.geoPoint?.latitude,
          longitude: nearestRetailer?.geoPoint?.longitude,
        });
        return nearestRetailer;
      };

      await asyncQueryAction(
        loadNearestRetailer,
        mutationTypes.FETCH_RETAILER_REQUEST,
        mutationTypes.FETCH_RETAILER_SUCCESS,
        mutationTypes.FETCH_RETAILER_FAILURE
      ).run({commit});
    }
  },
  async [actionTypes.LOAD_ANONYMOUS_RETAILER]({commit, state, dispatch}) {
    if (state.hasAnonymousRetailerLoaded) return;
    try {
      await dispatch(actionTypes.LOAD_NEAREST_RETAILER);
      commit(mutationTypes.SET_ANONYMOUS_RETAILER_FLAG, true);
      commit(mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED, false);
      commit(mutationTypes.SET_HAS_REFERRED_SHOP_RETAILER_LOADED, false);
    } catch (error) {
      console.error(error);
    }
  },
  async [actionTypes.LOAD_SESSION_RETAILER]({commit, dispatch}, payload) {
    commit(mutationTypes.FETCH_RETAILER_REQUEST);

    try {
      const retailer = await RetailerApi.getById(payload);
      dispatch(actionTypes.SET_SELECTED_LOCATION_FROM_COORDINATES, {
        latitude: retailer?.geoPoint?.latitude,
        longitude: retailer?.geoPoint?.longitude,
      });
      commit(mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED, false);
      commit(mutationTypes.SET_SELECTED_RETAILER, retailer);
    } catch (error) {
      console.error(error);
    } finally {
      commit(mutationTypes.FETCH_RETAILER_SUCCESS);
    }
  },
  async [actionTypes.SET_SELECTED_LOCATION_FROM_COORDINATES]({state, rootState, dispatch}, {latitude, longitude}) {
    if (state.selectedLocation.postcode) return;
    try {
      //runs coordinates through mapbox to ensure consistent postal code formatting
      const response = await getAddressFromCoordinates({
        longitude,
        latitude,
        accessToken: rootState?.backend?.mapboxApiKey,
      });
      const postcode = response.features.find((feature) => feature.id.includes('postcode'))?.text;
      if (!postcode) return;
      dispatch(actionTypes.SET_SELECTED_LOCATION_FROM_POSTCODE, postcode);
    } catch (error) {
      console.error('Error setting selected location from coordinates:', error);
    }
  },
  [actionTypes.SAVE_TOKENS]({commit, state, dispatch}, tokens) {
    if (!state.userEmail || !tokens.refreshToken) return;
    commit(mutationTypes.SET_ACCESS_TOKEN, tokens.accessToken);
    commit(mutationTypes.SET_IV, tokens.iv);
    commit(mutationTypes.SET_SESSIONID, tokens.sessionId);
    dispatch('fetchUserRoles');
    new CryptoUtils(state.userEmail).encrypt(tokens.refreshToken).then((encryptedRefreshToken) => {
      commit(mutationTypes.SET_REFRESH_TOKEN, encryptedRefreshToken);
    });
  },
  [actionTypes.LOGOUT_USER]({commit}) {
    commit(mutationTypes.CLEAR_USER_PERMISSIONS);
    commit(mutationTypes.CLEAR_USER);
  },
  async [actionTypes.INIT_SELECTED_LOCATION]({state, rootState, rootGetters, commit}) {
    const validationParams = {
      country: rootGetters['backend/countryCode'],
      accessToken: rootState?.backend?.mapboxApiKey,
    };

    //if we already have a selectedLocation - no need to initialize a new one
    //likewise, if we don't have a geolocated postcode or lat/long, we can't initialize a location, so return
    if (
      state.selectedLocation?.postcode ||
      (!rootState.backend.geolocation?.postalCode &&
        (!rootState.backend.geolocation?.latitude || !rootState.backend.geolocation?.longitude))
    ) {
      return commit(mutationTypes.SET_VALIDATED_POSTCODE, false);
    }

    let location = null;
    if (rootState.backend.geolocation?.accuracyRadiusWithinLimit === true) {
      //get location from postalcode
      if (rootState?.backend?.geolocation?.postalCode) {
        location = await getValidLocationFromPostCode({
          ...validationParams,
          postalCode: rootState?.backend?.geolocation?.postalCode,
        });
      } else {
        //else get location from coordinates
        location = await getValidLocationFromCoordinates({
          ...validationParams,
          latitude: rootState.backend.geolocation?.latitude,
          longitude: rootState.backend.geolocation?.longitude,
        });
      }
    }
    //TODO - is hasValidatedLocaiton needed? Seems like a relic of an older strategy.
    //I can't work out the purpose of this flag.
    if (state.hasValidatedLocation) {
      //TODO feels like this should be if(!location.postcode) return;
      if (!state.selectedLocation?.postcode) return;
      return commit(mutationTypes.SET_SELECTED_LOCATION, location);
    }

    if (!location?.postcode) {
      commit(mutationTypes.SET_VALIDATED_LOCATION, true);
      return commit(mutationTypes.CLEAR_SELECTED_LOCATION);
    }

    commit(mutationTypes.SET_SELECTED_LOCATION, location);
  },
  async [actionTypes.SET_SELECTED_LOCATION_FROM_POSTCODE]({state, rootState, rootGetters, commit}, postcode) {
    const location = await getValidLocationFromPostCode({
      postalCode: postcode,
      country: rootGetters['backend/countryCode'],
      accessToken: rootState?.backend?.mapboxApiKey,
    });
    state.selectedLocation = location;
    commit(mutationTypes.SET_SELECTED_LOCATION, location);
  },
  [actionTypes.CLEAR_USER_PREFERENCES]({commit}) {
    commit(mutationTypes.CLEAR_SELECTED_LOCATION);
    commit(mutationTypes.CLEAR_SELECTED_RETAILER);
    commit(mutationTypes.SET_VALIDATED_LOCATION, false);
    commit(mutationTypes.SET_HAS_PREFERRED_RETAILER_LOADED, false);
    commit(mutationTypes.SET_ANONYMOUS_RETAILER_FLAG, false);
  },
};

const user = {
  namespaced: true,

  state: {
    b2bUnit: {},
    loggedIn: window.ACC?.userLoggedIn || false,
    userEmail: (() => {
      const ACC = window.ACC;
      if (!ACC?.customVariables?.currentUserEmail) return;
      if (
        ACC?.customVariables?.currentUserEmail &&
        ACC?.customVariables?.currentUserEmail !== UserStatus.ANONYMOUS_USER
      ) {
        return ACC?.customVariables?.currentUserEmail;
      }
    })(),
    customer: undefined,
    selectedRetailer: {},
    selectedLocation: {},
    isRetailerLoading: false,
    isUserDataLoading: false,
    hasReferredShopRetailerLoaded: false,
    hasPreferredRetailerLoaded: false,
    hasAnonymousRetailerLoaded: false,
    hasValidatedLocation: false,
    accessToken: undefined,
    refreshToken: undefined,
    iv: undefined,
    sessionId: undefined,
    userRoles: {},
    userProfile: {},
    userGenderList: [],
    userName: (() => {
      if (!ACC?.customVariables?.currentUserName) return;
      return ACC?.customVariables?.currentUserName;
    })(),
    hasFetchRetailerError: false,
    trekUCompetencyLevel: undefined,
    userPreferredRetailer: null,
    drawerDeliveryAddress: {},
  },

  mutations: mutations,
  getters: getters,
  actions: actions,
};

export default user;
