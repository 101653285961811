import RetailerApi from '@/api/retailer-api';
import SizeChartApi from '@/api/size-chart';
import Vue from 'vue';
import {FulfillmentOptionTypes} from '@/constants/fulfillment-options';
import {DeliveryModeTypes} from '@/constants/delivery-modes';

const mutationTypes = Object.freeze({
  SET_PRICE_DATA: 'setPriceData',
  SET_RPA_VIEW_IN_STOCK: 'setRpaViewInStock',
  SET_PDP_DETAILS_LOADED: 'setPdpDetailsLoaded',
  SET_REQUESTED_PRODUCT_CODE: 'setRequestedProductCode',
  SET_SEARCH_RESULT_REFERENCE: 'setSearchResultReference',
  SET_SIZE_CHART_DATA: 'setSizeChartData',
  SET_PDP_SKU: 'setPdpSku',
  SET_MECHANIC_NOTES: 'setMechanicNotes',
  SET_LOCALLY_STOCK_AVAILABLE_RETAILERS: 'setLocallyStockAvailableRetailers',
  SET_LOCALLY_AVAILABLE_VARIANTS: 'setLocallyAvailableVariants',
  SET_IS_LOCALLY_CONSUMER_CHOICE_ACTIVE: 'setIsLocallyConsumerChoiceActive',
  SET_FULFILLMENT_OPTIONS: 'setFulfillmentOptions',
});

const ActionTypes = Object.freeze({
  FETCH_LOCALLY_AVAILABLE_VARIANTS: 'fetchLocallyAvailableVariants',
});

const pdpModule = {
  namespaced: true,

  state: {
    pdpSku: 0,
    priceData: {},
    isBike: false,
    rpaViewInStock: false,
    pdpDetailsLoaded: false,
    requestedProductCode: 0,
    searchResultReference: 0,
    electraAccessoryFinderToggle: false,
    pdpFunctionsMapping: {},
    fulfillmentOptions: [],
    isElectraBrand: false,
    activeTab: '',
    primaryProductUrl: '',
    primaryProductName: '',
    primaryProductMetaData: [{key: '', value: ''}],
    productQuantity: null,
    sizeChartData: null,
    isBuyingZoneBundleDialogVisible: false,
    isGridBundleDialogVisible: false,
    variantCode: '',
    isECommAvailable: false,
    isLocallyActive: false,
    category: {},
    mechanicNotes: [],
    pdpBaseProductCode: 0,
    product: null,
    initAttributeSelection: {},
    locallyStockAvailableRetailers: [],
  },
  getters: {
    getActiveTab(state) {
      return state.activeTab;
    },
    variantPrice(state) {
      return state.priceData?.displayPrice?.value;
    },
    locallyVariantProduct(state) {
      if (!state.product?.locallyAvailableVariants?.length) {
        return state.product;
      }
      const locallyAvailableVariantsSet = new Set(state.product.locallyAvailableVariants);
      const updatedVariants = state.product.variants.map((item) => ({
        ...item,
        isLocallyStockAvailable: locallyAvailableVariantsSet?.has(item.code),
      }));

      return {...state.product, variants: updatedVariants};
    },
    isBopisOnly(state) {
      return !!state.fulfillmentOptions.filter(
        (fo) =>
          fo?.code === FulfillmentOptionTypes.PICK_UP_IN_STORE &&
          fo?.deliveryModes?.every((mode) => mode.code.includes(DeliveryModeTypes.BOPIS))
      ).length;
    },
  },

  mutations: {
    [mutationTypes.SET_FULFILLMENT_OPTIONS](state, payload) {
      state.fulfillmentOptions = payload;
    },
    setPdpSku(state, pdpSku) {
      Vue.set(state, 'pdpSku', pdpSku);
    },

    setIsECommAvailable(state, payload) {
      Vue.set(state, 'isECommAvailable', payload);
    },

    setPriceData(state, payload) {
      Vue.set(state, 'priceData', payload);
    },

    setIsBike(state, payload) {
      state.isBike = payload;
    },

    setIsElectraBrand(state, payload) {
      state.isElectraBrand = payload;
    },

    setElectraAccessoryFinderToggle(state, payload) {
      state.electraAccessoryFinderToggle = payload;
    },

    setPdpFunctionsMapping(state, payload) {
      state.pdpFunctionsMapping = payload;
    },

    setRpaViewInStock(state, payload) {
      state.rpaViewInStock = payload;
    },

    setPdpDetailsLoaded(state, payload) {
      state.pdpDetailsLoaded = payload;
    },

    setRequestedProductCode(state, payload) {
      state.requestedProductCode = payload;
    },

    setSearchResultReference(state, payload) {
      state.searchResultReference = payload;
    },
    setActiveTab(state, payload) {
      Vue.set(state, 'activeTab', payload);
    },
    setPrimaryProductUrl(state, payload) {
      state.primaryProductUrl = payload;
    },

    setPrimaryProductName(state, payload) {
      state.primaryProductName = payload;
    },

    setPrimaryProductMetaData(state, payload) {
      state.primaryProductMetaData = payload;
    },

    setProductQuantity(state, payload) {
      state.productQuantity = payload;
    },
    setSizeChartData(state, payload) {
      state.sizeChartData = payload;
    },
    // Common mutation for two bundle modals. One for buying zone, other for sku-grid(both on pdp)
    setBundleDialogVisibility(state, payload) {
      if (payload.type === 'BUYING') {
        Vue.set(state, 'isBuyingZoneBundleDialogVisible', payload.isVisible);
      }

      if (payload.type === 'GRID') {
        Vue.set(state, 'isGridBundleDialogVisible', payload.isVisible);
      }
    },

    setIsVariantCode(state, payload) {
      state.variantCode = payload;
    },

    setIsLocallyActive(state, payload) {
      state.isLocallyActive = payload;
    },

    [mutationTypes.SET_IS_LOCALLY_CONSUMER_CHOICE_ACTIVE](state, payload) {
      state.isLocallyConsumerChoiceActive = payload;
    },

    setCategory(state, payload) {
      state.category = payload;
    },
    [mutationTypes.SET_MECHANIC_NOTES](state, payload) {
      state.mechanicNotes = payload ?? [];
    },
    setPdpBaseProductCode(state, payload) {
      state.pdpBaseProductCode = payload;
    },
    setProduct(state, payload) {
      state.product = payload;
    },
    setInitAttributeSelection(state, payload) {
      state.initAttributeSelection = payload;
    },
    [mutationTypes.SET_LOCALLY_STOCK_AVAILABLE_RETAILERS](state, payload) {
      state.locallyStockAvailableRetailers = payload;
    },
    [mutationTypes.SET_LOCALLY_AVAILABLE_VARIANTS](state, payload) {
      state.product = {...state.product, locallyAvailableVariants: payload};
    },
  },

  actions: {
    setVariantPdpSku({commit}, pdpSku) {
      commit(mutationTypes.SET_PDP_SKU, pdpSku);
    },

    setVariantPrice({commit}, priceData) {
      commit(mutationTypes.SET_PRICE_DATA, priceData);
    },

    setRpaViewInStockModalValue({commit}, viewInStockValue) {
      commit(mutationTypes.SET_RPA_VIEW_IN_STOCK, viewInStockValue);
    },
    updatePdpDetailsLoaded({commit}, pdpDetailsLoaded) {
      commit(mutationTypes.SET_PDP_DETAILS_LOADED, pdpDetailsLoaded);
    },
    updateRequestedProductCode({commit}, requestedProductCode) {
      commit(mutationTypes.SET_REQUESTED_PRODUCT_CODE, requestedProductCode);
    },
    updateSearchResultReference({commit}, searchResultReference) {
      commit(mutationTypes.SET_SEARCH_RESULT_REFERENCE, searchResultReference);
    },
    async fetchSizeChartData({commit}, productId) {
      try {
        const sizeChartData = await SizeChartApi.getSizeChartData(productId);
        commit(mutationTypes.SET_SIZE_CHART_DATA, sizeChartData);
        return true;
      } catch (error) {
        console.error(error);
        return false;
      }
    },
    setInitAttributeSelection({commit}, selection) {
      commit('setInitAttributeSelection', selection);
    },
    setlocallyStockAvailableRetailers({commit}, retailers) {
      commit(mutationTypes.SET_LOCALLY_STOCK_AVAILABLE_RETAILERS, retailers);
    },
    async [ActionTypes.FETCH_LOCALLY_AVAILABLE_VARIANTS]({commit, rootState, state}) {
      try {
        const options = {
          productId: state.product.code,
          retailerId: rootState?.user?.selectedRetailer?.name,
        };
        const locallyAvailableVariantsResp = await RetailerApi.getExternalInventoryVariantsDetails(options);
        const locallyInStockVariantData = locallyAvailableVariantsResp.data?.locallyInStockVariants || [];
        commit(mutationTypes.SET_LOCALLY_AVAILABLE_VARIANTS, locallyInStockVariantData);
      } catch (error) {
        console.error('Error in getting locally Variants', error);
      }
    },
  },
};

export default pdpModule;
