<template>
  <header
    id="trek-header"
    ref="trekHeader"
    qaid="nav-app"
    :class="[
      'fcp-reveal',
      'nav-app',
      'masthead',
      {'nav-app--is-electra': isElectraBrand, 'nav-app--is-trek': !isElectraBrand},
    ]"
    data-sticky-container
  >
    <div
      :id="isB2b ? 'js-sticky-header' : null"
      data-sticky
      data-sticky-on="small"
      data-options="marginTop:0;"
      data-top-anchor="1"
      class="w-full"
    >
      <div id="search" class="exitStageTop">
        <slot name="search" />
      </div>

      <!--  
        Phase 2: Unify the implementation for b2b and b2c
        Because b2b's header uses an inline-template, moving this markup into NavApp.vue
        requires a significant refactor. Let's save this for phase 2 when we address the functionality,
        html, css & etc. For now, I've used a slot.
      -->
      <template v-if="isB2b">
        <slot name="b2b" />
      </template>

      <section v-else class="header-wrap" :class="isMultiBrand ? 'header-wrap--multibrand' : null">
        <slot name="compact-container" />
        <slot name="navigation" />
        <slot name="minicart" />
        <slot name="geoprompt" />
      </section>

      <nav-overlay-large-layout />
    </div>
    <a
      v-if="!isB2b && language === 'en_US'"
      href="/us/en_US/company/legal_policies/privacy_policy_terms_of_use/#accessibility"
      class="sr-only"
    >
      View our accessibility statement
    </a>
  </header>
</template>

<script>
import {mapActions, mapMutations, mapState} from 'vuex';
import NavOverlayLargeLayout from '@/navigation/layouts/NavOverlayLargeLayout.vue';

export default {
  /**
   * TODO: Setting global viewport states should happen at the root level, not the nav level.
   * However, at this time the root level is split between shell-base and vueConfig so there
   * is no singular root level element to do this in. Since the nav will be global, it makes
   * sense to do this here for now.
   * */
  name: 'NavApp',
  components: {
    NavOverlayLargeLayout,
  },
  props: {
    canShowCart: {
      type: Boolean,
      default: false,
    },
    isB2b: {
      type: Boolean,
      default: false,
    },
    isMultiBrand: {
      type: Boolean,
      default: false,
    },
    isElectraBrand: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState('backend', ['language']),
    ...mapState('global', ['isCompactView']),
    ...mapState('viewSize', ['viewBreakpoint']),
  },
  watch: {
    viewBreakpoint: {
      immediate: true,
      handler(val) {
        this.updateViewportState(val);
      },
    },
    isCompactView() {
      this.unsetNav();
    },
  },
  unmounted: function () {
    this.observer && this.observer.disconnect();
  },
  mounted() {
    if (!this.isB2b) {
      this.hideFcpNav();
    }
    this.observeHeight();
  },
  methods: {
    observeHeight() {
      if (this.$refs?.trekHeader && !this.observer) {
        this.observer = new ResizeObserver(() => {
          this.updateHeaderHeight();
        });
        this.observer.observe(this.$refs.trekHeader);
        this.updateHeaderHeight();
      }
    },
    updateHeaderHeight() {
      this.setHeaderHeight(this.$refs.trekHeader.offsetHeight);
    },
    ...mapActions('global', ['updateViewportState']),
    ...mapActions('navigation', ['unsetNav']),
    ...mapMutations('global', ['setHeaderHeight']),
    hideFcpNav() {
      //Adding this class here keeps the css from being bundled into the fcp inline styles
      document.getElementById('nav-app-fcp').className += 'is-finished';
    },
  },
};
</script>

<style lang="scss" scoped>
.nav-app {
  &--is-trek {
    --nav-highlight-color: var(--red-100);
  }

  &--is-electra {
    --nav-highlight-color: var(--teal-100);
  }
}
</style>

<style>
#nav-app-fcp.is-finished {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  border: 0;
}
</style>
