import Vue from 'vue';
import vuex, {Store} from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import * as stores from './modules';
import navigation from '@/navigation/store/navigation';
import projectOne from '@/projectOne/store/project-one';
import preSeason from '@/preSeason/store/pre-season';
import bontragerCustom from '@/bontragerCustom/store/bontrager-custom-store';
import rcpBuilder from '@/rcpBuilder/store/rcp-builder';
import rcpChallenges from '@/rcpChallenges/store/rcp-challenges';
import rcpRouteList from '@/rcpRouteList/store/rcp-route-list.js';
import bikeGarage from '@/bikeGarage/store/bike-garage.js';
import rcEvents from '@/rideClubEvents/store/rc-events.js';

Vue.use(vuex);

const localACC = window.ACC || {};
const languageISO = localACC.languageISO || 'en';

// Enable dev tools
Vue.config.devtools = !window.ACC?.config.isProduction;

const defaultStores = Object.keys(stores)
  .filter((key) => typeof stores[key] === 'object')
  .reduce((acc, key) => {
    acc[key] = stores[key];
    return acc;
  }, {});

const store = new Store({
  state: {
    locale: window.ACC?.languageISO || null,
    vvLocale: languageISO.split('_')[0] || 'en',
  },

  mutations: {
    setState(state, payload) {
      Object.keys(payload).forEach((key) => {
        let value = payload[key];
        Vue.set(state, key, value);
      });
    },
  },

  modules: {
    ...defaultStores,
    navigation,
    projectOne,
    preSeason,
    bontragerCustom,
    rcpBuilder,
    rcpChallenges,
    rcpRouteList,
    bikeGarage,
    rcEvents,
  },

  plugins: [
    createPersistedState({
      paths: [
        'storage',
        'compare',
        'storeLocator',
        'user.accessToken',
        'user.refreshToken',
        'user.iv',
        'user.sessionId',
        'user.userRoles',
        'user.customer',
        'user.selectedRetailer',
        'user.selectedLocation',
        'user.hasValidatedLocation',
        'user.hasAnonymousRetailerLoaded',
        'user.hasPreferredRetailerLoaded',
        'user.hasReferredShopRetailerLoaded',
        'user.drawerDeliveryAddress',
        'preSeason.showSuccessAlert',
        'projectOne.cachedBuildId',
        'projectOne.cachedRedirectUrlB2c',
        'projectOne.cachedRedirectUrlB2b',
        'projectOne.cachedRedirectUrlAnonymous',
        'projectOne.hasQueuedSaveAction',
        'projectOne.queuedShareAction',
        'projectOne.cachedSelections',
        'rcpBuilder.routeDeletedMsg',
        'rcpRouteList.openRideTab',
        'rcpRouteList.pendingFileUploads',
        'checkout.outOfDealerDeliveryRangeToast',
        'checkout.billingAddress',
        'user.trekUCompetencyLevel',
        'rcEvents.eventIdToClone',
      ],
    }),
  ],
});

export const useStore = () => store;
export default store;
