class NumberFormatter {
  /**
   * @param {Object} formatters
   * @param {Object} formatters.priceFormatter
   * @param {Object} formatters.priceFormatter.currency
   * @param {string} formatters.priceFormatter.currency.isoCode - ISO code of current b2b unit currency
   * @example USD
   * @param {string} formatters.priceFormatter.currency.symbol - currency symbol of current b2b unit
   * @example $
   * @param {Object} formatters.priceFormatter.currency.digits
   * @param {Object} formatters.priceFormatter.locale
   * @param {string} formatters.priceFormatter.locale.isoCode - ISO code of current b2b unit locale
   * @example en_US
   */
  constructor(formatters) {
    const {priceFormatter} = formatters ?? {};
    const language = priceFormatter?.language?.isoCode ?? window.ACC.languageISO;

    this.locale = LocaleConfiguration?.[language] ?? null;
    this.currency = priceFormatter?.currency;
    this.priceFormatter = priceFormatter;

    try {
      const localeCode = (this.locale?.overriddenISOCodes?.locale ?? language)?.replaceAll('_', '-');
      const currency = this.locale?.overriddenISOCodes?.currency ?? this.currency?.isoCode;
      const minimumFractionDigits = this.locale?.numberFormatOptions?.minimumFractionDigits ?? this.currency?.digits;
      const maximumFractionDigits = this.locale?.numberFormatOptions?.maximumFractionDigits ?? this.currency?.digits;

      this.priceFormat = new Intl.NumberFormat(localeCode, {
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits,
      });
    } catch (error) {
      this.priceFormat = null;

      if (error instanceof RangeError) {
        console.error(error);
      }
    }
  }

  /**
   * Format number value to price string
   * @param {number} value
   * @returns {string} price
   */
  formatPrice(value) {
    if (typeof value !== 'number') {
      throw new TypeError(`Invalid NumberFormatter#formatPrice argument: type of ${value} must be number.`);
    }

    const parts = this.#valueToNormalizedParts(value, this.locale);

    return runPostProcessors(parts, this.locale?.postProcessors ?? [])
      .map((part) => part?.value)
      .join('');
  }

  /**
   * Replace default signs with ones from Hybris
   * @param {number} amount
   * @param {LocaleConfiguration} locale
   * @returns <{type: string, value: string}>[]
   */
  #valueToNormalizedParts(amount, locale) {
    return (
      this.priceFormat?.formatToParts(amount)?.map(({type, value}) => {
        // FE should use currency sign received from BE
        if (type === FormatPartType.currency) {
          const currency = locale?.overriddenParts?.[type] ?? this.currency.symbol;

          return {type, value: currency};
        }

        if (locale?.overriddenParts && type in locale.overriddenParts) {
          return {type, value: locale.overriddenParts[type]};
        }

        return {type, value};
      }) ?? []
    );
  }
}

const PostProcessor = {
  moveCurrencyToStart(parts) {
    const result = [...parts];
    const currencySign = this.extractPart(result, FormatPartType.currency);
    const literal = this.extractPart(result, FormatPartType.literal);

    result.unshift(literal);
    result.unshift(currencySign);

    return result;
  },
  moveCurrencyToEnd(parts) {
    const result = [...parts];
    const currencySign = this.extractPart(result, FormatPartType.currency);
    const literal = this.extractPart(result, FormatPartType.literal);

    result.push(literal);
    result.push(currencySign);

    return result;
  },
  extractPart(parts, partType) {
    const partIndex = parts.findIndex((part) => part.type === partType);
    const [extractedPart] = parts.splice(partIndex, 1);

    return extractedPart;
  },
};

function runPostProcessors(parts, postProcessors = []) {
  return postProcessors.reduce((result, postProcessor) => postProcessor(result), parts);
}

const FormatPartType = Object.freeze({
  currency: 'currency',
  decimal: 'decimal',
  group: 'group',
  fraction: 'fraction',
  infinity: 'infinity',
  integer: 'integer',
  literal: 'literal',
  minusSign: 'minusSign',
  nan: 'nan',
  plusSign: 'plusSign',
  percentSign: 'percentSign',
  unit: 'unit',
});

const LocaleConfiguration = Object.freeze({
  en_IN_TL: {
    overriddenISOCodes: {
      locale: 'en_US',
    },
  },
  es_IN_TL: {
    overriddenISOCodes: {
      locale: 'en_US',
    },
    overriddenParts: {
      [FormatPartType.group]: '.',
      [FormatPartType.decimal]: ',',
    },
  },
  en_ZA: {
    overriddenParts: {
      [FormatPartType.group]: ' ',
      [FormatPartType.decimal]: '.',
    },
  },
  en_IN: {
    overriddenISOCodes: {
      locale: 'en_US',
      currency: 'USD',
    },
    overriddenParts: {
      [FormatPartType.currency]: '₹',
      [FormatPartType.group]: ',',
      [FormatPartType.decimal]: '.',
    },
    numberFormatOptions: {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    },
  },
  fr_BE: {
    overriddenParts: {
      [FormatPartType.group]: '.',
    },
  },
  fr_CH: {
    overriddenParts: {
      [FormatPartType.group]: "'",
    },
    postProcessors: [PostProcessor.moveCurrencyToStart.bind(PostProcessor)],
  },
  fr_FR: {
    overriddenParts: {
      [FormatPartType.group]: ',',
      [FormatPartType.decimal]: '.',
    },
  },
  fr_CA: {
    overriddenParts: {
      [FormatPartType.group]: '.',
    },
  },
  de_CH: {
    overriddenParts: {
      [FormatPartType.group]: "'",
    },
  },
  pt_PT: {
    overriddenParts: {
      [FormatPartType.group]: '.',
    },
  },
  it_IT: {
    postProcessors: [PostProcessor.moveCurrencyToStart.bind(PostProcessor)],
  },
  da_DK: {
    postProcessors: [PostProcessor.moveCurrencyToStart.bind(PostProcessor)],
  },
});

export default NumberFormatter;
