import {getRootCssPropertyValue} from '@/utils/css-var-util';

const RouteBuilderClickModes = Object.freeze({
  ADD_WAYPOINT: 'ADD_WAYPOINT',
  CUSTOM_CUE: 'CUSTOM_CUE',
  CONTROL_POINT: 'CONTROL_POINT',
});

const DEFAULT_POLYLINE_COLOR = getRootCssPropertyValue('--red-100') || '#ce1a2b';
const PREVIEW_POLYLINE_COLOR = getRootCssPropertyValue('--gray-100') || '#1a1a1a';

const RouteBuilderMapDefaults = Object.freeze({
  DEFAULT_MAP_ZOOM: 14,
  DEFAULT_ROUTING_PROFILE: 'cycling',
  DEFAULT_POLYLINE_COLOR,
  PREVIEW_POLYLINE_COLOR,
  DEFAULT_POLYLINE_WIDTH: 3,
  DEFAULT_COORDS_PRECISION: 6,
  ROUTE_EXISTS: 'route-exists',
  TOAST_DURATION: 4500,
  MAX_MAP_ZOOM: 19,
  MIN_MAP_ZOOM: 3,
  ZOOM_DURATION: 500,
  ZOOM_BOUNDS_PADDING: 48,
  ERROR_IN_DIRECTIONS: 'Error in direction',
  UNDO_REDO_STACK_SIZE_LIMIT: 10,
  TOTAL_ROUTE_DISTANCE: 4828032,
});

const RouteBuilderMapMarkers = Object.freeze({
  MAIN_MARKER_SVG: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <g clip-path="url(#clip0_7370_33264)">
                            <path d="M12 2C8.13 2 5 5.13 5 9C5 10.74 5.5 12.37 6.41 13.84C7.36 15.38 8.61 16.7 9.57 18.24C10.04 18.99 10.38 19.69 10.74 20.5C11 21.05 11.21 22 12 22C12.79 22 13 21.05 13.25 20.5C13.62 19.69 13.95 18.99 14.42 18.24C15.38 16.71 16.63 15.39 17.58 13.84C18.5 12.37 19 10.74 19 9C19 5.13 15.87 2 12 2ZM12 11.75C10.62 11.75 9.5 10.63 9.5 9.25C9.5 7.87 10.62 6.75 12 6.75C13.38 6.75 14.5 7.87 14.5 9.25C14.5 10.63 13.38 11.75 12 11.75Z" fill="#1A1A1A"/>
                          </g>
                          <defs>
                            <clipPath id="clip0_7370_33264">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                          </defs>
                        </svg>`,
  WAYPOINT_SVG: `<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="12" r="9" fill="white" stroke="#1A1A1A" stroke-width="2"/>
                        <defs>
                            <clipPath id="clip0_7370_33264">
                              <rect width="24" height="24" fill="white"/>
                            </clipPath>
                         </defs>
                    </svg>
                    `,
  CUE_SVG: `<svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" fill="currentcolor" class="text-gray-70">
              <path d="M20 2H4V16L12 22L20 16V2Z"></path>
            </svg>`,
});

const RouteBuilderMapStyle = Object.freeze({
  STANDARD: 'mapbox://styles/jaendrestrek/cm60vbcae009101qzhf2n562z',
  SATELLITE: 'mapbox://styles/mapbox/satellite-streets-v12',
});

const RouteBuilderRoutingOptions = Object.freeze({
  CYCLING: 'cycling',
  DRIVING: 'driving',
  WALKING: 'walking',
});

const RouteBuilderHoverMarkers = Object.freeze({
  HOVER_MARKER_SVG: `<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
   <circle opacity="0.3" cx="8" cy="8" r="7" fill="currentColor"/>
   <circle cx="8" cy="8" r="7.5" stroke="currentColor"/>
 </svg>`,
});

const RouteBuilderDefaultMapLocation = Object.freeze({
  latitude: 43.179021,
  longitude: -89.003741,
  address: '801 W Madison St, Waterloo, WI 53594, USA',
});

const RouteListColumnKeys = Object.freeze({
  NAME: 'name',
  EDIT: 'edit',
  LOCATION: 'location',
  DATE_CREATED: 'dateCreated',
  DISTANCE: 'distance',
  TOTAL_ELEVATION: 'totalElevationGain',
  PUBLIC_ROUTE: 'publicRoute',
  DELETE: 'delete',
  START_DATE: 'startDate',
});

const RouteBuilderSpeedTimeDefaults = Object.freeze({
  RIDE_TIME: '00:00',
  MPH_DEFAULT: 12,
  KPH_DEFAULT: 19,
  MPH_MAX: 30,
  KPH_MAX: 48,
});

const RouteBuilderRoutePrivacyModes = Object.freeze({
  PUBLIC: 'public',
  PRIVATE: 'private',
});

const RouteBuilderRouteSurfaceTypes = Object.freeze({
  PAVED: 'paved',
  UNPAVED: 'unpaved',
});

const MarketingSaveRouteDefaults = Object.freeze({
  MARKETING_PUBLISHER: 'Trek Bicycle',
  MARKETING_COUNTRY: 'US',
});

const carReplacementOptions = Object.freeze({YES: true, NO: false});

const RideMetricsTermKeys = Object.freeze({
  AVG: 'avg',
  MAX: 'max',
  CAR_REPLACEMENT_TRIP: 'carReplacementTrip',
  AVERAGE_CADENCE: 'averageCadence',
  CALORIES_BURNED: 'caloriesBurned',
  MAX_ALTITUDE: 'maxAltitude',
  MAX_GRADE: 'maxGrade',
  AVERAGE_TEMPERATURE: 'averageTemperature',
});

const RideUploadStatuses = Object.freeze({
  PENDING: 'PENDING',
  PROCESSING: 'PROCESSING',
  COMPLETE: 'COMPLETE',
  FAILED: 'FAILED',
  INDETERMINATE: 'INDETERMINATE',
});

export {
  RouteBuilderClickModes,
  RouteBuilderMapDefaults,
  RouteBuilderMapMarkers,
  RouteBuilderMapStyle,
  RouteBuilderRoutingOptions,
  RouteBuilderHoverMarkers,
  RouteBuilderDefaultMapLocation,
  RouteListColumnKeys,
  RouteBuilderSpeedTimeDefaults,
  RouteBuilderRoutePrivacyModes,
  RouteBuilderRouteSurfaceTypes,
  MarketingSaveRouteDefaults,
  carReplacementOptions,
  RideMetricsTermKeys,
  RideUploadStatuses,
};
