import {useVuelidate} from '@vuelidate/core';
import vuelidateConfig from '@/config/vuelidate.config';
import {
  helpers,
  required,
  email,
  sameAs,
  maxLength,
  minLength,
  requiredUnless,
  requiredIf,
  maxValue,
  between,
} from '@vuelidate/validators';
import {
  bodyParams,
  heightRemainder,
  password,
  greaterThanZero,
  numberOtherThanZero,
  url,
  pastDate,
} from '@/config/validation/validators';
import validationMessages from '@/config/validation/validation-messages';

const LocaleConfiguration = {
  kr: {
    MAX_BYTES: 30,
  },
  jp: {
    MAX_BYTES: 40,
  },
};

const byteCheck = (otherName, locale) =>
  helpers.withParams({type: 'fullNameByteCheck', value: otherName}, (value) => {
    if (!LocaleConfiguration[locale]) return true;
    const length = new TextEncoder().encode(value + otherName).length;
    return length <= LocaleConfiguration[locale].MAX_BYTES;
  });

const TrekValidationMixin = {
  setup() {
    const v$ = useVuelidate(vuelidateConfig);
    return {v$};
  },
  data() {
    return {trekValidators: [], trekValidatorMessages: []};
  },
  watch: {
    '$i18n.locale': {
      handler: function () {
        this.populateValidators();
      },
      immediate: true,
    },
  },
  methods: {
    getErrorMessage(fieldName) {
      // this.errorFields is populated from backend meta.feedback
      if (this.errorFields?.[fieldName]) {
        return this.errorFields[fieldName];
      }
      //this.v$ errors are populated from vuelidate.
      if (this.v$[fieldName]?.$errors) {
        return this.v$[fieldName].$errors[0]?.$message;
      } else if (this.v$.$errors?.length) {
        // check for propertyPath match (if fieldName is an object)
        const errorObj = this.v$.$errors.find((e) => e.$propertyPath === fieldName);
        if (errorObj) {
          return errorObj.$message;
        }
      }
    },
    populateValidators() {
      const firstPart = this.$i18n.locale.split('_')[0];
      this.trekValidatorMessages =
        validationMessages[this.$i18n.locale] || validationMessages[firstPart] || validationMessages.default;
      this.trekValidators = {
        required: (fieldName) =>
          helpers.withMessage(() => this.trekValidatorMessages.required(`"${this.$t(fieldName)}"`), required),
        email: (fieldName) =>
          helpers.withMessage(() => this.trekValidatorMessages.email(`"${this.$t(fieldName)}"`), email),
        password: () =>
          helpers.withMessage(
            () => this.$t('checkout.confirmation.characters.error') + '<br>' + this.$t('register.infoPassword'),
            password
          ),
        sameAs: (fieldName, targetField, targetFieldName) =>
          helpers.withMessage(
            () => this.trekValidatorMessages.confirmed(this.$t(`${fieldName}`), [this.$t(`${targetFieldName}`)]),
            sameAs(targetField)
          ),

        maxLength: (fieldName, max) =>
          helpers.withMessage(() => this.trekValidatorMessages.max(this.$t(`${fieldName}`), [max]), maxLength(max)),

        maxValue: (fieldName, max) =>
          helpers.withMessage(
            () => this.trekValidatorMessages.max_value(this.$t(`${fieldName}`), [max]),
            maxValue(max)
          ),
        requiredWithCustomMessage: (message) => helpers.withMessage(() => this.$t(`${message}`), required),
        maxLengthWithCustomMessage: (message, max) => helpers.withMessage(() => this.$t(`${message}`), maxLength(max)),
        minLengthWithCustomMessage: (message, min) => helpers.withMessage(() => this.$t(`${message}`), minLength(min)),
        bodyParams: () => helpers.withMessage(() => this.$t('garage.user.height.error'), bodyParams),
        heightRemainder: () => helpers.withMessage(() => this.$t('garage.user.height.error'), heightRemainder),
        greaterThanZero: (message) => helpers.withMessage(() => this.$t(`${message}`), greaterThanZero),
        numberOtherThanZero: (message) => helpers.withMessage(() => this.$t(`${message}`), numberOtherThanZero),

        fullNameMaxByteLength: (nameValue, locale) =>
          helpers.withMessage(() => this.$t('address.error.formentry.invalid.length'), byteCheck(nameValue, locale)),
        requiredUnless: (fieldName, prop) =>
          helpers.withMessage(
            () => this.trekValidatorMessages.required(`"${this.$t(fieldName)}"`),
            requiredUnless(prop)
          ),

        requiredIf: (fieldName, prop) =>
          helpers.withMessage(() => this.trekValidatorMessages.required(`"${this.$t(fieldName)}"`), requiredIf(prop)),
        requiredIfWithCustomMessage: (message, prop) =>
          helpers.withMessage(() => this.$t(`${message}`), requiredIf(prop)),
        requiredUnlessWithCustomMessage: (message, prop) =>
          helpers.withMessage(() => this.$t(`${message}`), requiredUnless(prop)),

        customValidation: (message, validator) => helpers.withMessage(() => this.$t(`${message}`), validator),

        betweenWithCustomMessage: (message, min, max) =>
          helpers.withMessage(() => this.$t(`${message}`), between(min, max)),
        maxValueWithCustomMessage: (message, max) => helpers.withMessage(() => this.$t(`${message}`), maxValue(max)),
        isURL: (message) => helpers.withMessage(() => this.$t(`${message}`), url),
        pastDate: (message) => helpers.withMessage(() => this.$t(`${message}`), pastDate),
      };
    },
  },
};
export {TrekValidationMixin};
